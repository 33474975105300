.chart-container {
    display: flex;
    flex-direction: row;
    padding: 16px; /* Equivalent to p: 3 */
  }
  
  .chart-section {
    flex: 3;
  }
  
  .controls-section {
    flex: 1;
    margin-left: 16px; /* Equivalent to ml: 2 */
    display: flex;
    flex-direction: column;
  }
  
  .form-control {
    margin-bottom: 16px; /* Adds spacing between form controls */
  }


  