/* AssignedAccountsTable.css */

.assigned-accounts-paper {
    padding: 0px;
    max-width: 100%;
    margin-top: 0px;
    position: relative;
    background-color: #1e1e1e;
    color: #f5f5f5;
}

.assigned-accounts-grid-container {
    height: 300px;
    width: 100%;
    background-color: #2e2e2e;
    color: #f5f5f5;
}

.assigned-accounts-grid {
    background-color: #2e2e2e;
    color: white !important;
    font-size: 0.8em;
}

.assigned-accounts-error {
    color: red;
    margin-top: 16px;
}

/* Alternating row colors for stripes */
.assigned-accounts-grid .MuiDataGrid-row.even {
    background-color: rgba(255, 255, 255, 0.1);
}

.assigned-accounts-grid .MuiDataGrid-row.odd {
    background-color: rgba(255, 255, 255, 0.05);
}

/* Header styling */
.assigned-accounts-grid .MuiDataGrid-columnHeaders {
    background-color: #3a3a3a;
    color: #ffffff;
}

/* Cell styling */
.assigned-accounts-grid .MuiDataGrid-cell {
    border-color: #555;
}