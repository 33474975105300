

/* Production.css */

.production-container {
  display: flex;
  flex-direction: column;
  height: 100%; /* Fill the parent container */
  width: 100%;
  box-sizing: border-box;
}

.scroller-and-detail {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden; /* Prevent overflow from scroller and detail */
}

.scroller-container {
  flex: 0 0 250px; /* Fixed height for the scroller */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden;
  padding: 0; /* Optional padding */
}
.prod-detail-container {
  flex: 1 1 auto; /* Flexible height for the detail calendar */
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent external scrolling */
}
