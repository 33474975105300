/* src/components/FrontDesk.css */
.frontdesk-container {
    padding: 24px;
    max-width: 100%;
    margin-top: 32px;
    position: relative;
    background-color: #1E1E1E;
  }
  
  .frontdesk-title {
    color: #BB86FC;
    margin-bottom: 24px;
  }
  
  .frontdesk-content {
    flex-grow: 1;
    height: calc(100vh - 150px);
    overflow: hidden;
  }
  
  .frontdesk-grid {
    height: 100%;
  }
  
  .email-list-container, .email-details-container {
    height: 100%;
    overflow: auto;
    background-color: #2C2C2C;
    border: 1px solid #3C3C3C;
  }