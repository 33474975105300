/* CalendarDetail.css */

.calendar-detail-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #121212;
    color: #FFFFFF;
    flex: 1;
    flex-grow: 1;
   
   
    overflow: auto !important;
  
    box-sizing: border-box;
  
  
  }
  
  .calendar-view {
    display: flex;
    flex-direction: column;
    overflow: auto !important;
  
    /* Flexible height for the detail calendar */
    min-height: 0;
 
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    /* Critical for flexbox to allow children to shrink */
  
  }
  
  .calendar-header {
    display: flex;
    justify-content: space-between;
    /* Space between navigation and summary */
    align-items: center;
    /* Vertically center items */
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #1E1E1E;
    border-bottom: 1px solid #424242;
    flex-direction: row;
    /* Ensure row direction */
    height: 84px;
    flex-shrink: 0;
    /* Prevent header from shrinking */
  }
  
  .calendar-header-left {
    display: flex;
    align-items: center;
   /* flex: 1; */
    /* Take up available space on the left */
  }
  
  .navigation-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    /* Spacing between buttons and title */
    width: 100%;
    max-width: 600px;
    /* Adjust as needed to control spacing */
  }
  
  .prev-button,
  .next-button {
    flex: 0 0 auto;
  }
  
  .month-year-title {
    flex: 1;
    text-align: center;
    min-width: 150px;
    /* Fixed minimum width to prevent shifting */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.2rem;
    font-weight: bold;
    color: #FFFFFF;
  }
  
  /* Monthly Summary Container */
  .monthly-summary-container {
    display: flex;
    align-items: center;
    /* Ensure it stays on the right */
  }
  
  /* Navigation Buttons */
  .month-nav-button {
    border-color: #424242 !important;
    color: #FFFFFF !important;
    background-color: #333333 !important; /* Add this line */

   }
  
  .month-nav-button:hover {
    border-color: #FFFFFF;
    background-color: #333333 !important; /* Add this line */

  }
  
  /* Calendar Grid */
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 0px;
    background-color: #2C2C2C;
    padding: 1px;
    overflow: auto !important;
  }
  
  .weekday-header {
    display: contents;
  }
  
  .weekday-header-cell {
    background-color: #1E1E1E;
    padding: 8px 0;
    text-align: center;
    border: 1px solid #424242;
    font-weight: bold;
    color: #B0B0B0;
    border-radius: 4px;
  }
  
  .weeks-container {
    display: contents;
  }
  
  .week-row {
    display: contents;
  }
  
  .day-cell-container,
  .summation-cell-container {
  
    box-sizing: border-box;
  }
  
  .empty-day-cell {
    background-color: #2C2C2C;
    height: 100%;
  }
  
  .summation-cell-container {
    padding: 0px;
    box-sizing: border-box;
    height: 100%;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .weekday-header-cell {
      font-size: 0.8rem;
      padding: 4px 0;
      border-radius: 2px;
    }
  
    .month-year-title {
      font-size: 1rem;
      min-width: 120px;
      /* Adjust width for smaller screens */
    }
  
    .day-cell-container,
    .summation-cell-container {
      min-height: 60px;
    }
  
    .calendar-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .calendar-header-left {
      flex: none;
      width: 100%;
    }
  
    .navigation-container {
      gap: 8px;
      margin-top: 8px;
      max-width: 100%;
    }
  
    .month-year-title {
      min-width: 100px;
      /* Further adjust width if necessary */
    }
  
    .monthly-summary-container {
      width: 100%;
      margin-top: 8px;
    }
  }