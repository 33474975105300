/* src/components/EmailList.css */
.email-list {
    padding: 0;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #BB86FC #2C2C2C;
  }
  
  /* Webkit browsers like Chrome/Safari */
  .email-list::-webkit-scrollbar {
    width: 8px;
  }
  
  .email-list::-webkit-scrollbar-track {
    background: #2C2C2C;
  }
  
  .email-list::-webkit-scrollbar-thumb {
    background-color: #BB86FC;
    border-radius: 4px;
    border: 2px solid #2C2C2C;
  }
  
  .email-list-item {
    border-bottom: 1px solid #3C3C3C;
    transition: background-color 0.3s;
  }
  
  .email-list-item:hover {
    background-color: #3C3C3C;
  }
  
  .email-list-item.selected {
    background-color: #4A4A4A;
  }
  
  .email-list-item.legitimate {
    border-left: 4px solid #4CAF50;
  }
  
  .email-list-item.not-legitimate {
    border-left: 4px solid #F44336;
  }
  
  .email-subject {
    font-weight: bold;
    color: #E0E0E0;
  }
  
  .email-sender {
    color: #BB86FC;
  }
  
  .email-time {
    color: #A0A0A0;
    margin-left: 8px;
  }
  
  .status-chip {
    margin-left: 8px;
  }
  
  .status-chip.analyzed {
    background-color: #4CAF50;
    color: #FFFFFF;
  }
  
  .status-chip.pending {
    background-color: #FFC107;
    color: #000000;
  }