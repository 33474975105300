.mini-calendar-container-x {
    flex: 0 0 auto; /* Prevents the MiniCalendar from shrinking */
    margin-right: 0;
  }
  
  .dayHeader-x {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  

  