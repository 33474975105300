/* src/components/EmailDetails.css */
.email-details {
    padding: 16px;
  }
  
  .email-details-title {
    color: #BB86FC;
    margin-bottom: 16px;
  }
  
  .no-email-selected {
    color: #A0A0A0;
    text-align: center;
    margin-top: 24px;
  }
  
  .email-info, .ai-summary, .parts-table {
    background-color: #2C2C2C;
    padding: 16px;
    margin-bottom: 16px;
  }
  
  .ai-summary {
    background-color: #3C3C3C;
  }
  
  .parts-table {
    overflow-x: auto;
  }
  
  .action-buttons {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }