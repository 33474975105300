/* CustomerTabSet.css */

.customer-tabset-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
    width: 100%;
    overflow: hidden; /* Prevent main page from scrolling */
    background-color: #121212; /* Optional: Set a background color */
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #424242;
    background-color: #1E1E1E; /* Optional: Background for header */
}

.customer-title {
    color: #FFFFFF;
    font-weight: bold;
}

.salesperson-info {
    color: gray;
}

.tabs-container {
    border-bottom: 1px solid #424242;
    background-color: #2C2C2C; /* Optional: Background for tabs */
}

.tab-item {
    color: gray;
    min-width: 100px;
    font-weight: bold;
}

.tab-item.Mui-selected {
    color: white !important; /* Color for selected tab */
    font-weight: bold;
}

.MuiTab-root.Mui-selected {
    color: #ffffff !important; /* Ensures selected tab text is always white */
    font-weight: bold !important;
}

.tab-item.active {
    color: white;
}

.tab-content-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Prevent main scrolling */
}

.proficiency-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevent scrolling */
    padding: 16px;
}

.proficiency-text {
    color: white;
    font-size: 1.2rem;
}
