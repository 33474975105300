.paper {
    padding: 0px;
    max-width: 100%;
    margin-top: 0px;
    position: relative;
    background-color: #1e1e1e; /* Dark background */
    color: #f5f5f5; /* Brighter text */
}

.dataGridContainer {
    height: 500px;
    width: 100%;
    background-color: #2e2e2e; /* Dark grid background */
    color: #f5f5f5; /* Brighter text */
}

.dataGrid {
    background-color: #2e2e2e; /* Dark grid background */
    color: white !important; /* Brighter text */
}




.error {
    color: red;
    margin-top: 16px;
}

/* Alternating row colors for stripes */
.MuiDataGrid-row.even {
    background-color: rgba(255, 255, 255, 0.1);
}

.MuiDataGrid-row.odd {
    background-color: rgba(255, 255, 255, 0.05);
}

/* Header styling */
.MuiDataGrid-columnHeaders {
    background-color: #3a3a3a;
    color: #ffffff;
}

/* Cell styling */
.MuiDataGrid-cell {
    border-color: #555;
}
