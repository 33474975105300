.filterControls {
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #555;
    background-color: #2e2e2e; /* Dark background */
    color: #f5f5f5; /* Brighter text */
}

.filterControls > * {
    margin-bottom: 0px;
}

.filterControls .MuiSlider-root {
    margin-top: 0px;
    margin-bottom: 0px;
}

.filterControls .MuiTypography-root {
    margin-top: 0px;
    color: #ffffff;
}

.filterControls .MuiFormControlLabel-root {
    display: block;
    margin-left: 0;
}

.filterControls .MuiCheckbox-root {
    padding-left: 0;
}
