.version-history-card {
    margin: 20px;
    padding: 20px;
  }
  
  .version-history-title {
    margin-bottom: 20px;
  }
  
  .version-history-grid {
    height: 400px;
  }
  
  .loading-message,
  .error-message,
  .no-data-message {
    margin: 20px;
    padding: 20px;
    text-align: center;
  }
  
  .error-message {
    color: #f44336;
  }