/* CustomerLandingPage.css */

/* Global Box-Sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}


.customer-landing-page {
  background-color: #000000 !important;
  color: #ffffff !important;
  padding: 16px !important;

  display: flex !important;
  flex-direction: column !important;
  overflow: hidden !important;
}

/* Grid Containers   flex: 1 !important; */
.grid-container {

  overflow: hidden !important;
}

.grid-item {
  display: flex !important;
  flex-direction: column !important;
}

/* Section Styling */
.section {
  background-color: #1e1e1e !important;
  border: 1px solid gray !important;
  padding-top: 16px !important;
  padding-left: 16px !important;
  padding-top: 16px !important;
  margin-bottom: 16px !important;
  display: flex !important;
  flex-direction: column !important;
  flex: 1 !important;
  overflow: hidden !important;
  border-radius: 8px !important; /* Attractive round borders */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important; /* Subtle shadow for depth */
  position: relative !important; /* For positioning the button */
}

/* Section Title */
.section-title {
  color: #ffd700 !important; /* Bold yellow title text */
  margin-bottom: 16px !important;
  font-size: 1.5rem !important; /* Adjust as needed */
  font-weight: bold !important;
}

/* Section Content */
.section-content {
  flex: 1 !important; /* Allows the content to grow and take available space */
  overflow: auto !important; /* Enables scrolling if content overflows */
  padding-bottom: 16px !important; /* Space at the bottom */
}

/* Nested Grid Containers */
.nested-grid-container {
  flex: 1 !important;
  overflow: hidden !important;
}

.nested-grid-item {
  overflow: hidden !important;
}

/* Info Items */
.info-item {
  display: flex !important;
  margin-bottom: 8px !important;
}

.info-label {
  flex: 1 !important;
  color: #b3b3b3 !important;
  word-break: break-word !important;
  font-size: 1rem !important; /* Adjust as needed */
}

.info-value {
  flex: 1 !important;
  font-weight: bold !important;
  word-break: break-word !important;
  font-size: 1rem !important; /* Adjust as needed */
}

/* Hold Note Styling */
.hold-note {
  background-color: #2e2e2e !important;
  border: 1px solid #444 !important;
  padding: 8px !important;
  margin-top: 16px !important;
  max-height: 120px !important;
  overflow-y: auto !important;
  border-radius: 4px !important;
}

.note-title {
  font-weight: bold !important;
  margin-bottom: 4px !important;
  font-size: 1rem !important;
}

/* Alert Status */
.alert-status {
  font-weight: bold !important;
  margin-bottom: 8px !important;
  font-size: 1rem !important;
  color: #ff4500 !important; /* Example color for alert */
}

/* Memo Box Styling */
.memo-box {
  background-color: #2e2e2e !important;
  border: 1px solid #444 !important;
  padding: 8px !important;
  margin-top: 16px !important;
  max-height: 120px !important;
  overflow-y: auto !important;
  border-radius: 4px !important;
}

/* Alert Box Styling */
.alert-box {
  background-color: #2e2e2e !important;
  border: 1px solid #444 !important;
  padding: 8px !important;
  margin-top: 8px !important;
  max-height: 120px !important;
  overflow-y: auto !important;
  border-radius: 4px !important;
}

/* Box Titles and Texts */
.box-title {
  font-weight: bold !important;
  margin-bottom: 4px !important;
  font-size: 1rem !important;
}

.box-date {
  font-style: italic !important;
  margin-bottom: 4px !important;
  font-size: 0.95rem !important;
}

.box-text {
  margin-bottom: 4px !important;
  word-break: break-word !important;
  font-size: 0.95rem !important;
}

/* Section Subtitles */
.section-subtitle {
  font-weight: bold !important;
  margin-bottom: 4px !important;
  font-size: 1rem !important;
}

.section-text {
  margin-bottom: 4px !important;
  word-break: break-word !important;
  font-size: 0.95rem !important;
}

/* View More Button Styling */
.view-more-button {
  align-self: flex-end !important; /* Aligns the button to the right */
  margin-top: auto !important; /* Pushes the button to the bottom */
  background-color: #FDB813 !important; /* Gold background */
  color: #000000 !important; /* Black text */
  border: none !important;
  border-radius: 4px !important;
  padding: 6px 12px !important;
  font-size: 0.9rem !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
}

.view-more-button:hover {
  background-color: #e6c200 !important; /* Darker gold on hover */
}

/* Additional Enhancements */

/* Attractive Round Borders for Papers */
.section {
  border-radius: 8px !important; /* Already added above, reinforcing */
}

/* Rounded Corners for Internal Boxes */
.hold-note,
.memo-box,
.alert-box {
  border-radius: 4px !important;
 
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .section-title {
    font-size: 1.2rem !important;
  }

  .info-label,
  .info-value,
  .box-title,
  .alert-status {
    font-size: 0.9rem !important;
  }

  .box-text,
  .section-text {
    font-size: 0.85rem !important;
  }

  .hold-note,
  .memo-box,
  .alert-box {
    max-height: 100px !important;
  }

  .view-more-button {
    padding: 5px 10px !important;
    font-size: 0.8rem !important;
  }
}
