/* Customer.css */

.customer-paper {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Allow the Paper to fill the available space */
    height: 100%;
    width: 100%;
    padding: 0; /* Remove padding to prevent overflow */
    margin: 0; /* Remove margin */
    box-sizing: border-box;
    overflow: hidden; /* Prevent Paper from scrolling */
}
