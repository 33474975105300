/* SummationCell.css */

.summation-cell {
    display: grid;
    grid-template-columns: 1fr 1fr; /* SUM and AVG */
    grid-template-rows: auto auto;  /* Quotes and Sales */
    gap: 2px;
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 4px;
    box-sizing: border-box;
    background-color: #333333;
    border: 1px solid #424242;
    height: 100%;
    width: 100%;
}

.summation-cell.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2C2C2C;
}

.summation-headers {
    grid-column: 1 / 3; /* Span both SUM and AVG */
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    margin-left:10px;
    margin-right:10px;
}

.header {
    font-weight: bold;
    color: #B0B0B0;
    font-size: 0.75rem;
}

.summation-row {
    display: contents; /* Allows child elements to align with the grid */
    font-weight: bold !important;
}

.quotes-row {
    grid-row: 2 / 3;
}

.sales-row {
    grid-row: 3 / 4;
}

.summation-item {
    display: flex;
    align-items: center;
     
    background-color: inherit; /* Inherit background color based on row */
   
    border-radius: 2px;
    font-weight: bold;
}

.quotes-row .summation-item {
    background-color: #1976d2; /* Blue for Quotes */
}

.sales-row .summation-item {
    background-color: #2D875D; /* Green for Sales */
}

.icon-small {
    font-size: 0.7rem; /* Smaller icon size */
}

.activity-text {
    font-size: 0.7rem;
    color: #FFFFFF;
}
