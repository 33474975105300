.horizontal-calendar-container {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0px 0;

}
 
.horizontal-calendar-scroller {
  display: flex;
  flex-direction: row;
  min-width: fit-content;
}
